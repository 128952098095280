/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const process_voucher = /* GraphQL */ `
  mutation Process_voucher(
    $email: String!
    $code: String!
    $voucher_limit: Int!
    $sender: String!
    $client: String!
    $employee_number: String
    $post_code: String
  ) {
    process_voucher(
      email: $email
      code: $code
      voucher_limit: $voucher_limit
      sender: $sender
      client: $client
      employee_number: $employee_number
      post_code: $post_code
    ) {
      id
      campaign_id
      code
      email
      assigned_timestamp
      sent_timestamp
      resent_timestamp
      pharmacy_used
      pharmacy_used_at
      booking_used
      booking_used_at
      booking_used_source
      active
      employee_number
      post_code
      pharmacy_used_source
      access_code
    }
  }
`;
